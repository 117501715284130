/*!
Copyright (C) 2020 Liberty Infrasystems. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postUploadAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

class CurrentEnterprise {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async get(request) {
        return getJson(`${this.url}`, request, { requestHeaders: this.requestHeaders });
    }

    async delete() {
        return postJsonAcceptJson(`${this.url}/delete/enterprise`, null, null, { requestHeaders: this.requestHeaders });
    }

    async check(request) {
        return postJsonAcceptJson(`${this.url}/check/enterprise`, request, null, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorized client programs
 */
class Client {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available client tokens assigned to this enterprise
     */
    async search(query) {
        return getJson(`${this.url}/search/client`, query, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a resource class to manage authorization tokens for client software
 * using the server's Client API.
 */
class ClientToken {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/client-token`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/client-token`, null, query, { requestHeaders: this.requestHeaders });
    }

    /**
     * Search available client tokens assigned to this enterprise
     */
    async search(query) {
        return getJson(`${this.url}/search/client-token`, query, { requestHeaders: this.requestHeaders });
    }
}

// TODO: separate realm-image into RealmImage class; can loginshield info be represented as a realm setting instead of special API?
class Realm {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async get(query) {
        return getJson(`${this.url}/state/realm`, query, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/create/realm`, request, null, { requestHeaders: this.requestHeaders });
    }

    async delete(request) {
        return postJsonAcceptJson(`${this.url}/delete/realm`, request, null, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/realm`, request, query, { requestHeaders: this.requestHeaders });
    }

    async search(request) {
        return getJson(`${this.url}/search/realm`, request, { requestHeaders: this.requestHeaders });
    }

    async editImage(query, request) {
        return postUploadAcceptJson(`${this.url}/edit/realm-image`, request, query, { requestHeaders: this.requestHeaders });
    }

    async deleteImage(query) {
        return postJsonAcceptJson(`${this.url}/delete/realm-image`, null, query, { requestHeaders: this.requestHeaders });
    }

    async getImage(query) {
        return getJson(`${this.url}/state/realm-image`, query, { requestHeaders: this.requestHeaders });
    }

    async getLoginShieldInfo(query) {
        return getJson(`${this.url}/state/realm-loginshield`, query, { requestHeaders: this.requestHeaders });
    }

    async finishWebauthzRequest(query, request) {
        return postJsonAcceptJson(`${this.url}/grant/webauthz`, request, query, { requestHeaders: this.requestHeaders });
    }

    // async updateLoginShieldRealmInfo(query, request) {
    //     return postJsonAcceptJson(`${this.url}/edit/realm-loginshield`, request, query, { requestHeaders: this.requestHeaders });
    // }
}

class LinkEnterpriseUser {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    // TODO: move this to an invitation table; link-enterprise-user record will be created automatically when user accepts invitation
    // async create(request) {
    //     return postJsonAcceptJson(`${this.url}/create/link-enterprise-user`, request, null, { requestHeaders: this.requestHeaders });
    // }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/link-enterprise-user`, null, query, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/link-enterprise-user`, request, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/link-enterprise-user`, query, { requestHeaders: this.requestHeaders });
    }
}

class Invite {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async check(id, request) {
        return postJsonAcceptJson(`${this.url}/check/invite`, request, { id }, { requestHeaders: this.requestHeaders });
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/invite`, request, null, { requestHeaders: this.requestHeaders });
    }

    async get(id) {
        return getJson(`${this.url}/state/invite`, { id }, { requestHeaders: this.requestHeaders });
    }

    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/invite`, request, query, { requestHeaders: this.requestHeaders });
    }

    async delete(query) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/delete/invite`, null, query, { requestHeaders: this.requestHeaders });
    }

    async search(query) {
        return getJson(`${this.url}/search/invite`, query, { requestHeaders: this.requestHeaders });
    }
}

class UnicornSprings {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    // quick status -- is there a unicorn springs customer enterprise? is it active? is the user's profile active?
    async check(request) {
        return postJsonAcceptJson(`${this.url}/rpc/check-unicornsprings-customer`, request, null, { requestHeaders: this.requestHeaders });
    }

    /*
    // set up unicorn springs customer enterprise and user; responds with redirect url
    async setup(request) {
        return postJsonAcceptJson(`${this.url}/rpc/setup-unicornsprings-customer`, request, null, { requestHeaders: this.requestHeaders });
    }
    */

    // more detailed information about an existing unicorn springs enterprise and user
    async report(request) {
        return postJsonAcceptJson(`${this.url}/rpc/report-unicornsprings-customer`, request, null, { requestHeaders: this.requestHeaders });
    }

    // sign in to unicorn springs to set up or manage enterprise; responds with redirect url
    async connect(request) {
        return postJsonAcceptJson(`${this.url}/rpc/connect-unicornsprings-customer`, request, null, { requestHeaders: this.requestHeaders });
    }
}

/**
 * NOTE: moved this here from the loginfront client ... but haven't changed the implementation yet. the idea is that
 * enterprise admin only has to do webauthz with brandprofile ONCE per domain, and we'll record it in our brandprofile reservation
 * table, and then the enterprise admin can reuse that brandprofile in multiple places without having to go back for permission
 * for everything.
 */
class BrandProfile {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async search(query) {
        return getJson(`${this.url}/search/brandprofile`, query, { requestHeaders: this.requestHeaders });
    }

    async startConnect(request = {}) {
        return postJsonAcceptJson(`${this.url}/rpc/start-brandprofile-partner-connect`, request, /* query: */ null, { requestHeaders: this.requestHeaders });
    }

    async verifyConnect(token) {
        return postJsonAcceptJson(`${this.url}/rpc/verify-brandprofile-partner-connect`, { token }, /* query: */ null, { requestHeaders: this.requestHeaders });
    }
}

/**
 * This is a client for the browser and it uses session cookies to authenticate to the server.
 */
class BrowserClient {
    constructor(context = {}) {
        this.self = new CurrentEnterprise(context);
        this.brandprofile = new BrandProfile(context);
        this.client = new Client(context);
        this.clientToken = new ClientToken(context);
        this.realm = new Realm(context);
        // this.domain = new Domain(context);
        // this.dynamicSharedDomain = new DynamicSharedDomain(context);
        // this.dynamicDnsRecord = new DynamicDnsRecord(context);
        // this.form = new Form(context);
        // this.formEntry = new FormEntry(context);
        this.invite = new Invite(context);
        this.linkEnterpriseUser = new LinkEnterpriseUser(context);
        // this.linkUserVolume = new LinkUserVolume(context); // TODO: delete
        // this.volume = new Volume(context); // TODO: delete
        // this.website = new Website(context); // TODO: delete
        // this.websiteTlsCertificate = new WebsiteTlsCertificate(context); // TODO: delete
        this.unicornsprings = new UnicornSprings(context);
    }
}

export default BrowserClient;

export {
    BrandProfile,
    CurrentEnterprise,
    Client,
    ClientToken,
    Invite,
    LinkEnterpriseUser,
    Realm,
    UnicornSprings,
};
