import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/**
 * The 'layout' meta parameter indicates the page layout to use for that view; see plugins/layouts.js for options.
 * Authentication is required by default for all views. The `isPublic` meta parameter indicates that a view
 * is public and does not require authentication to display.
 */
const router = new Router({
    mode: 'history',
    base: process.env.VUE_APP_WEBSITE_PATH || '/',
    routes: [
        {
            path: '/',
            name: 'front',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "front" */ '../views/Front.vue'),
        },
        {
            path: '/signup',
            name: 'signup',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "signup" */ '../views/Signup.vue'),
        },
        {
            path: '/login',
            name: 'login',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        },
        {
            path: '/logout',
            name: 'logout',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
        },
        {
            path: '/interaction',
            name: 'interaction',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "interaction" */ '../views/Interaction.vue'),
        },
        {
            path: '/session',
            name: 'session',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "session" */ '../views/Session.vue'),
        },
        {
            path: '/dashboard',
            name: 'user-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "dashboard" */ '../views/user/Dashboard.vue'),
        },
        {
            path: '/profile',
            name: 'profile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Index.vue'),
        },
        {
            path: '/profile/setup',
            name: 'profile-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-setup" */ '../views/profile/Setup.vue'),
        },
        {
            path: '/profile/authn/loginfront',
            name: 'profile-settings-authn-loginfront',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authn-loginfront" */ '../views/profile/authn/LoginFront.vue'),
        },
        {
            path: '/profile/authz',
            name: 'profile-settings-authz',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-authz" */ '../views/profile/Authz.vue'),
        },
        {
            path: '/profile/session',
            name: 'profile-settings-session',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-settings-session" */ '../views/profile/Session.vue'),
        },
        {
            path: '/profile/delete',
            name: 'profile-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "profile-delete" */ '../views/profile/Delete.vue'),
        },
        {
            path: '/service',
            name: 'service-admin',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin" */ '../views/service/Index.vue'),
        },
        {
            path: '/service/setup',
            name: 'service-admin-setup',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-setup" */ '../views/service/Setup.vue'),
        },
        {
            path: '/service/settings',
            name: 'service-admin-settings',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-settings" */ '../views/service/Settings.vue'),
        },
        // {
        //     path: '/service/c2',
        //     name: 'service-admin-c2',
        //     meta: { layout: 'admin-layout' },
        //     component: () => import(/* webpackChunkName: "service-admin-c2" */ '../views/service/C2.vue'),
        // },
        {
            path: '/service/dns',
            name: 'service-admin-dns',
            meta: { layout: 'admin-layout' },
            component: () => import(/* webpackChunkName: "service-admin-dns" */ '../views/service/Dns.vue'),
        },
        {
            path: '/enterprise',
            name: 'user-enterprise-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-enterprise-list" */ '../views/user/EnterpriseList.vue'),
        },
        {
            path: '/user/create/enterprise',
            name: 'user-create-enterprise',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "user-create-enterprise" */ '../views/user/CreateEnterprise.vue'),
        },
        {
            path: '/search',
            name: 'search',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
        },
        {
            path: '/enterprise/:enterpriseId',
            name: 'enterprise-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "enterprise-dashboard" */ '../views/enterprise/EnterpriseDashboard.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/settings',
            name: 'enterprise-settings',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "enterprise-settings" */ '../views/enterprise/EnterpriseSettings.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/setup',
            name: 'enterprise-setup',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "enterprise-setup" */ '../views/enterprise/Setup.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/delete',
            name: 'enterprise-delete',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "enterprise-delete" */ '../views/enterprise/Delete.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/create/client-token',
            name: 'enterprise-create-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "enterprise-create-client-token" */ '../views/enterprise/CreateClientToken.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/search/client-token',
            name: 'enterprise-search-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "enterprise-search-client-token" */ '../views/enterprise/SearchClientToken.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/search/user',
            name: 'enterprise-search-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "enterprise-search-user" */ '../views/enterprise/SearchUser.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/user/:userId',
            name: 'enterprise-view-user',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "enterprise-view-user" */ '../views/enterprise/ViewUser.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/edit/brandprofile',
            name: 'enterprise-edit-brandprofile',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "enterprise-edit-brandprofile" */ '../views/enterprise/EditBrandProfile.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id',
            name: 'cryptium_id-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-dashboard" */ '../views/enterprise/cryptium_id/CryptiumIdDashboard.vue'),
        },
        // {
        //     path: '/enterprise/:enterpriseId/settings',
        //     name: 'enterprise-settings',
        //     meta: { layout: 'main-layout' },
        //     component: () => import(/* webpackChunkName: "enterprise-settings" */ '../views/enterprise/EnterpriseSettings.vue'),
        // },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/search/realm',
            name: 'cryptium_id-search-realm',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-search-realm" */ '../views/enterprise/cryptium_id/SearchRealm.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/create-realm',
            name: 'cryptium_id-create-realm',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-create-realm" */ '../views/enterprise/cryptium_id/CreateRealm.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/realm/:realmId',
            name: 'cryptium_id-realm-dashboard',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-realm-dashboard" */ '../views/enterprise/cryptium_id/RealmDashboard.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/realm/:realmId/settings',
            name: 'cryptium_id-realm-settings',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-realm-settings" */ '../views/enterprise/cryptium_id/RealmSettings.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/realm/:realmId/webauthz/grant',
            name: 'cryptium_id-realm-webauthz-grant',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-realm-webauthz-grant" */ '../views/enterprise/cryptium_id/RealmWebauthzGrant.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/realm/:realmId/user-list',
            name: 'cryptium_id-realm-user-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-realm-user-list" */ '../views/enterprise/cryptium_id/RealmUserList.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/realm/:realmId/user/:userId',
            name: 'cryptium_id-realm-user-view',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-realm-user-view" */ '../views/enterprise/cryptium_id/RealmUserView.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/realm/:realmId/create/client-token',
            name: 'cryptium_id-realm-create-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-realm-create-client-token" */ '../views/enterprise/cryptium_id/CreateClientToken.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/realm/:realmId/edit/client',
            name: 'cryptium_id-realm-edit-client',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-realm-edit-client" */ '../views/enterprise/cryptium_id/EditClient.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/realm/:realmId/delete/client-token',
            name: 'cryptium_id-realm-delete-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-realm-delete-client-token" */ '../views/enterprise/cryptium_id/DeleteClientToken.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/realm/:realmId/search/client',
            name: 'cryptium_id-realm-search-client',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-realm-search-client" */ '../views/enterprise/cryptium_id/SearchClient.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/realm/:realmId/search/client-token',
            name: 'cryptium_id-realm-search-client-token',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-realm-search-client-token" */ '../views/enterprise/cryptium_id/SearchClientToken.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/realm/:realmId/profile-request-list',
            name: 'cryptium_id-realm-profile-request-list',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-realm-profile-request-list" */ '../views/enterprise/cryptium_id/RealmProfileRequestList.vue'),
        },
        {
            path: '/enterprise/:enterpriseId/cryptium_id/realm/:realmId/delete',
            name: 'cryptium_id-delete-realm',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "cryptium_id-delete-realm" */ '../views/enterprise/cryptium_id/DeleteRealm.vue'),
        },
        {
            path: '/webauthz/grant',
            name: 'webauthz-grant',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-grant" */ '../views/webauthz/Grant.vue'),
        },
        {
            path: '/webauthz/prompt',
            name: 'webauthz-prompt',
            meta: { layout: 'main-layout' },
            component: () => import(/* webpackChunkName: "webauthz-prompt" */ '../views/webauthz/Prompt.vue'),
        },
//////////////////////////
/////////
//////////////////////////
/////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
//////////
/////////////////
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            meta: { layout: 'main-layout', isPublic: true },
            component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue'),
        },
    ],
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);
    next();
});

export default router;
