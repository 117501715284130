/*!
Copyright (C) 2020 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, class-methods-use-this, max-classes-per-file */

const ajax = require('axios');

async function getJson(path, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.get(path, {
        headers: {
            Accept: 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

async function postJsonAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request ? JSON.stringify(request) : undefined, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}

/*
async function postUploadAcceptJson(path, request, query = null, { requestHeaders = {} } = {}) {
    const response = await ajax.post(path, request, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            ...requestHeaders,
        },
        params: query,
    });
    return response.data;
}
*/

class Realm {
    constructor(context) {
        this.url = context.serviceEndpoint;
        this.requestHeaders = context.requestHeaders;
    }

    async create(request) {
        return postJsonAcceptJson(`${this.url}/create/realm`, request, null, { requestHeaders: this.requestHeaders });
    }

    async search(request) {
        return getJson(`${this.url}/search/realm`, request, { requestHeaders: this.requestHeaders });
    }

    // query should be like { id }
    async get(query) {
        return getJson(`${this.url}/state/realm`, query, { requestHeaders: this.requestHeaders });
    }

    // query should be like { id }
    async edit(query, request) {
        // NOTE: you only need to specify the attributes that should be changed
        return postJsonAcceptJson(`${this.url}/edit/realm`, request, query, { requestHeaders: this.requestHeaders });
    }

    // query should be like { id }
    async delete(query) {
        return postJsonAcceptJson(`${this.url}/delete/realm`, query, null, { requestHeaders: this.requestHeaders });
    }

    // query should be like { id, recent_days: 7 }
    async report(query) {
        return getJson(`${this.url}/report/realm`, query, { requestHeaders: this.requestHeaders });
    }
}

class BrowserClient {
    constructor(context = {}) {
        this.realm = new Realm(context);
    }
}

export default BrowserClient;
export {
    Realm,
};
