import EnterpriseClient from './client/enterprise';
// import EnterpriseRealmClient from './client/enterprise_realm';
import LoginFrontClient from './client/loginfront';
import LoginFrontRealmClient from './client/loginfront_realm';
import MainClient from './client/main';
import UserClient from './client/user';
import CryptiumIdClient from './client/cryptium_id';
import CryptiumIdRealmClient from './client/cryptium_id_realm';

/* eslint-disable dot-notation */

const clientMap = {}; // org id -> client configured for that organization
const client = {
    enterprise(enterpriseId) {
        const idx = `enterprise:${enterpriseId}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new EnterpriseClient({ endpoint: '/', serviceEndpoint: `/api/browser/enterprise/${enterpriseId}`, enterpriseId });
        }
        return clientMap[idx];
    },
    /*
    enterpriseRealm(enterpriseId, realmId) {
        const idx = `enterpriseRealm:${enterpriseId}:${realmId}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new EnterpriseRealmClient({
                endpoint: '/',
                serviceEndpoint: `/api/browser/enterprise/${enterpriseId}/realm/${realmId}`,
                enterpriseId,
                realmId,
            });
        }
        return clientMap[idx];
    },
    */
    cryptiumId(enterpriseId) {
        const idx = `cryptium_id:${enterpriseId}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new CryptiumIdClient({ endpoint: '/', serviceEndpoint: `/api/browser/enterprise/${enterpriseId}/cryptium_id`, enterpriseId });
        }
        return clientMap[idx];
    },
    cryptiumIdRealm(enterpriseId, realmId) {
        const idx = `cryptium_id_realm:${enterpriseId}:${realmId}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new CryptiumIdRealmClient({
                endpoint: '/',
                serviceEndpoint: `/api/browser/enterprise/${enterpriseId}/cryptium_id/realm/${realmId}`,
                enterpriseId,
                realmId,
            });
        }
        return clientMap[idx];
    },
    loginfront(enterpriseId) {
        const idx = `loginfront:${enterpriseId}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new LoginFrontClient({ endpoint: '/', serviceEndpoint: `/api/browser/enterprise/${enterpriseId}/loginfront`, enterpriseId });
        }
        return clientMap[idx];
    },
    loginfrontRealm(enterpriseId, realmId) {
        const idx = `loginfront_realm:${enterpriseId}:${realmId}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new LoginFrontRealmClient({
                endpoint: '/',
                serviceEndpoint: `/api/browser/enterprise/${enterpriseId}/loginfront/realm/${realmId}`,
                enterpriseId,
                realmId,
            });
        }
        return clientMap[idx];
    },
    main() {
        if (!clientMap['main']) {
            clientMap['main'] = new MainClient({ endpoint: '/', serviceEndpoint: '/api/browser' });
        }
        return clientMap['main'];
    },
    user(userId) {
        const idx = `user:${userId}`;
        if (!clientMap[idx]) {
            clientMap[idx] = new UserClient({ endpoint: '/', serviceEndpoint: `/api/browser/user/${userId}`, userId });
        }
        return clientMap[idx];
    },
};

export default client;
